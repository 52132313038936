/**
 * 
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
  }
  
  companies = {
    get: (id) => $app.api.get([ 'companies*', id, ]),
    stats: (id) => $app.api.get([ 'companies*/stats', id, ]),
    excel: (id) => $app.api.get([ 'companies*/excel', id, ]),
  }

  sms = {
    get: (companyId) => $app.api.get([ 'sms/companies*', companyId, ]),
    check: (id) => $app.api.post([ 'check-sms*', id, ]),
  }

  logs = {
    get: (companyId) => $app.api.get([ 'logs/companies*', companyId, ]),
  }
}
