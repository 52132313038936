export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: '', name: 'login', icon: 'genderless', component: () => import('pages/login/Index'), },
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '/messages', name: 'messages', title: 'Сообщения', icon: 'genderless', component: () => import('pages/index/Index'), },
      { path: '/logs', name: 'logs', title: 'Логи', icon: 'genderless', component: () => import('pages/logs/Index'), },
      { path: '/stats', name: 'stats', title: 'Статистика', icon: 'genderless', component: () => import('pages/stats/Index'), },
    ], },
  ], },
]
